<template>
    <div>
        <div
            class="loading"
            :class="{
                'loading--large': props.large,
                'loading--primary': props.color === 'primary',
                'loading--white': props.color === 'white',
                'loading--media': props.color === 'media',
            }"
        />
    </div>
</template>

<script setup lang="ts">
    export interface Props {
        color?: 'primary' | 'white' | 'media';
        large?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        color: 'primary',
        large: false,
    });
</script>

<style lang="postcss">
    .loading {
        @apply relative pointer-events-none h-4 w-4;

        &::after {
            @apply absolute block h-4 w-4 align-x-y -mt-2 -ml-2 border-2 border-solid rounded-full z-1;
            @apply border-r-transparent border-t-transparent;

            animation: loading 0.5s infinite linear;
            content: '';
        }

        &--large {
            @apply h-10 w-10;

            &::after {
                @apply h-10 w-10 -mt-5 -ml-5 border-4;
            }
        }

        &--primary::after {
            @apply border-primary-500;
            @apply border-r-transparent border-t-transparent;
        }

        &--white::after {
            @apply border-white;
            @apply border-r-transparent border-t-transparent;
        }

        &--media::after {
            @apply border-primary-500 dark:border-white;
            @apply !border-r-transparent !border-t-transparent;
        }
    }

    @keyframes loading {
        0% {
            transform: rotate(0);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
